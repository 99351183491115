import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm.js";

const stripeTestPromise = loadStripe(String(process.env.PUBLIC_KEY));
const Stripe = (props) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm priceId={props.priceId} />
    </Elements>
  );
};
export default Stripe;